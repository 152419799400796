<!-- ================:=========================================================================
  File Name: EditDomain.vue
  Description: User Edit Page

========================================================================================== -->

<template>
  <div id="set-new">
    <vx-card>
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Details" icon-pack="feather" icon="icon-edit">
            <div class="tab-text">
              <set-details-tab :data="setData" v-on:update_setData="setDataUpdate" v-on:insert_setData="createSet" />
            </div>
          </vs-tab>
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import SetDetailsTab from './SetDetailsTab.vue';

export default {
  components: {
    SetDetailsTab,
  },
  data() {
    return {
      setData: {
        set_name: undefined,
        set_description: undefined,
        set_status: undefined,
        set_thumbnail: undefined,
        set_practice_enabled: false,
        set_flashcard_enabled: false,
      },

      activeTab: 0,
    };
  },
  watch: {
    activeTab() {
      //tab change
    },
  },
  methods: {
    setDataUpdate(data) {
      this.setData = data;
    },
    createSet() {
      const pl = {
        name: this.setData.set_name,
        description: this.setData.set_description,
        status: this.setData.set_status === undefined ? 'active' : this.setData.set_status,
        icon: this.setData.set_thumbnail,
        practice_enabled: this.setData.set_practice_enabled,
        flashcard_enabled: this.setData.set_flashcard_enabled,
      };

      if (pl.name === undefined) {
        return this.$vs.notify({
          title: 'Failed to create set',
          text: 'Please provide a Set Name',
          color: 'danger',
          position: 'top-right',
        });
      }

      this.$vs.loading();

      this.$http
        .post('flashcards/sets/create', pl)
        .then((response) => {
          this.$vs.loading.close();

          this.$vs.notify({
            title: 'Created set successfully',
            text: `New local set ${pl.name} has been created.`,
            color: 'success',
            position: 'top-right',
          });

          if (response.status === 200) {
            const data = response.data;
            this.$router.push(`/a/study/sets/${data.id}`);
          }
        })
        .catch((exception) => {
          let error = 'An unknown error occurred while creating this set';
          if (exception.response) {
            error = exception.response.data.message;
          }

          return this.$vs.notify({
            title: 'Failed to create set',
            text: error,
            color: 'danger',
            position: 'top-right',
          });
        });
    },
  },
};
</script>
